import React from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { APP_COLOURS } from "../APP_VARS";

export const Services = (props) => {
	const services = [
		{
			title: "Civil Litigation",
			imgSrc: "img/08/litigation.png",
			link: "/civil-litigation/",
		},
		{
			title: "Family Law",
			imgSrc: "img/08/family-law.png",
			link: "/family-law/",
		},
		{
			title: "Criminal Defense",
			imgSrc: "img/08/criminal-law.png",
			link: "/criminal-defense/",
		},
	];

	const other = [
		{
			title: "URGENT BAIL LINE",
			desc: <a href='tel:0798404717'>079 840 4717</a>,
			desc2: <a href='tel:0798981454'>079 898 1454</a>,
			imgSrc: "",
			link: "",
		},
		{
			title: "CONTACT US",
			desc: <a href='mailto:rs@ahslaw.co.za'>rs@ahslaw.co.za</a>,
			desc2: <a href='tel:0129912875'>012 991 2875</a>,
			imgSrc: "",
			link: "",
		},
	];

	return (
		<View
			style={{
				width: "100%",
				// padding: "10%",
				backgroundColor: "#f6f6f6",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<View
				style={{
					width: "100%",
					// backgroundColor: "red",
					backgroundColor: "#FFF",
					justifyContent: "center",
					alignItems: "center",
					padding: "3%",
				}}>
				<Text
					style={{
						fontSize: window.innerWidth < 1000 ? 22 : 35,
						fontWeight: "700",
						// opacity: 0.8,
						color: APP_COLOURS.BLUE,
					}}>
					LEGAL SERVICES
				</Text>
			</View>

			<View
				style={{
					width: "100%",
					// backgroundColor: "red",
					justifyContent: "center",
					alignItems: "center",
					padding: 20,
					flex: 1,
					flexDirection: window.innerWidth < 1000 ? "comun" : "row",
					maxWidth: 1200,
					marginVertical: 50,
				}}>
				{services.map((service, index) => (
					<div className='w-full md:w-1/3 px-4 mb-8' key={index}>
						<TouchableOpacity
							onPress={() => {
								window.location.href = service.link;
							}}>
							<div className='bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden transition-transform transform hover:translate-y-[-5px] hover:shadow-lg'>
								<figure className='p-4 flex justify-center'>
									<a href={service.link} tabIndex='-1'>
										<img
											src={service.imgSrc}
											alt={service.title}
											className='w-24 h-20 object-contain'
										/>
									</a>
								</figure>
								<div className='p-4 text-center'>
									<h4 className='text-xl font-semibold text-gray-800 mb-2'>
										<a
											href={service.link}
											className='hover:text-blue-400'>
											<Text
												style={{
													fontSize: 20,
													fontWeight: "500",
													color: APP_COLOURS.BLUE,
												}}>
												{service.title}
											</Text>
										</a>
									</h4>
								</div>
							</div>
						</TouchableOpacity>
					</div>
				))}
			</View>
			<View
				style={{
					width: "100%",
					maxWidth: 1500,
					justifyContent: "center",
					alignItems: "center",
					marginBottom: "5%",
				}}>
				<View
					style={{
						width: "100%",
						// backgroundColor: "red",
						maxWidth: 800,
						justifyContent: "center",
						alignItems: "center",
						padding: 20,
						textAlign: "center",
						color: APP_COLOURS.BLUE,
					}}>
					<p>
						We offer a wide array of services which include but are
						not exclusive to assistance with{" "}
						<strong>arrests</strong>,{" "}
						<strong>bail applications</strong>,{" "}
						<strong>court bail</strong>,{" "}
						<strong>police bail</strong>,{" "}
						<strong>representation at trial</strong>, related
						representation for the matters in the{" "}
						<strong>civil courts</strong>,{" "}
						<strong>protection orders</strong>,{" "}
						<strong>appeals</strong>, and <strong>reviews</strong>.
					</p>
					<p style={{ marginTop: "40px" }}>
						We have a wide variety of experience in the following
						areas which include but are not exclusive to driving
						while under the influence, possession of illegal
						substances, assault, sexual assault, assault with the
						intent to inflict grievous bodily harm, theft, culpable
						homicide, attempted murder, arson, malicious injury to
						property, murder, robbery, commercial crimes and
						domestic violence.
					</p>
				</View>
			</View>
			<View
				style={{
					width: "100%",
					// backgroundColor: "red",
					justifyContent: "center",
					alignItems: "center",
					padding: 20,
					flex: 1,
					flexDirection: window.innerWidth < 1000 ? "comun" : "row",
					maxWidth: 1200,
					marginVertical: 50,
				}}>
				{other.map((d, index) => (
					<div className='w-full md:w-1/3 px-4 mb-8' key={index}>
						<View
							style={{
								flex: 1,
								backgroundColor: "#FFF",
								borderRadius: 15,
								borderWidth: 0.5,
								borderColor: "#797979",
								padding: "5%",
							}}>
							<Text
								style={{
									fontSize: 20,
								}}>
								{d.desc2}
							</Text>
							<Text
								style={{
									fontSize: 20,
								}}>
								{d.desc}
							</Text>
							<Text
								style={{
									fontSize: 20,
									fontWeight: "600",
									marginTop: 10,
								}}>
								{d.title}
							</Text>
						</View>
					</div>
				))}
			</View>
		</View>
	);
};

export default Services;
