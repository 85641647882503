import React, { Component } from "react";
import { View, Text } from "react-native-web";

export default class ContactPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					padding: 40,
					width: "100%",
					alignItems: "center",
					backgroundColor: "#f8f9fa",
				}}>
				<View
					style={{
						width: "100%",
						// backgroundColor: "#FFF",
						justifyContent: "center",
						alignItems: "center",
						padding: "5%",
					}}>
					<h1 className='text-5l sm:text-5l md:text-6l lg:text-6xl font-bold leading-tight text-black text-left lg:text-left'>
						Contact us
					</h1>
				</View>

				<View
					style={{
						maxWidth: 1100,
						width: "100%",
						// padding: 40,
						borderRadius: 20,
						overflow: "hidden",
						backgroundColor: "#FFF",
						// boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
					}}>
					<div
						style={{
							padding: "5%",
						}}
						className='p-6  text-gray-800'>
						<section>
							<h2 className='text-2xl font-semibold mb-6 text-center'>
								Connect with Us
							</h2>
							<div className='flex justify-center space-x-8'>
								<a
									href='https://www.facebook.com/ahstander/'
									target='_blank'
									rel='noopener noreferrer'
									className='group'
									aria-label='Facebook'>
									<div className='flex items-center justify-center w-12 h-12 bg-blue-600 rounded-full text-white text-2xl transition-transform transform group-hover:scale-110 group-hover:bg-blue-700 shadow-lg'>
										<i className='fab fa-facebook-f'></i>
									</div>
								</a>
								<a
									href='https://www.linkedin.com/company/ahstander/'
									target='_blank'
									rel='noopener noreferrer'
									className='group'
									aria-label='LinkedIn'>
									<div className='flex items-center justify-center w-12 h-12 bg-blue-700 rounded-full text-white text-2xl transition-transform transform group-hover:scale-110 group-hover:bg-blue-800 shadow-lg'>
										<i className='fab fa-linkedin-in'></i>
									</div>
								</a>
								<a
									href='https://twitter.com/'
									target='_blank'
									rel='noopener noreferrer'
									className='group'
									aria-label='Twitter'>
									<div className='flex items-center justify-center w-12 h-12 bg-blue-400 rounded-full text-white text-2xl transition-transform transform group-hover:scale-110 group-hover:bg-blue-500 shadow-lg'>
										<i className='fab fa-twitter'></i>
									</div>
								</a>
								<a
									href='https://www.instagram.com/'
									target='_blank'
									rel='noopener noreferrer'
									className='group'
									aria-label='Instagram'>
									<div className='flex items-center justify-center w-12 h-12 bg-pink-500 rounded-full text-white text-2xl transition-transform transform group-hover:scale-110 group-hover:bg-pink-600 shadow-lg'>
										<i className='fab fa-instagram'></i>
									</div>
								</a>
							</div>
						</section>

						<section className='mb-8'>
							<h3 className='text-2xl font-semibold mb-2'>
								Urgent Bail Line
							</h3>
							<ul className='list-disc pl-5 space-y-2'>
								<p>079 840 4717</p>
								<p>079 898 1454</p>
							</ul>
						</section>

						<section className='mb-8'>
							<h3 className='text-2xl font-semibold mb-2'>
								Contact Info
							</h3>
							<ul className='list-disc pl-5 space-y-2'>
								<p>
									<a
										href='https://goo.gl/maps/mM57RPNpsfBy743j9'
										target='_blank'
										rel='noopener noreferrer'
										className='text-blue-500 hover:underline'>
										486 Kentucky Street, Faerie Glen,
										Pretoria
									</a>
								</p>
								<p>012 991 2875</p>
								<p>
									<a
										href='mailto:rs@ahslaw.co.za'
										className='text-blue-500 hover:underline'>
										rs@ahslaw.co.za
									</a>
								</p>
							</ul>
						</section>

						<section className='mb-8'>
							<h3 className='text-2xl font-semibold mb-4'>
								Find Us on the Map
							</h3>
							<div
								style={{
									borderRadius: "12px",
									overflow: "hidden",
									boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
									cursor: "pointer",
									transition: "transform 0.2s",
								}}
								onClick={() =>
									window.open(
										"https://goo.gl/maps/mM57RPNpsfBy743j9",
										"_blank",
									)
								}>
								<iframe
									title='Google Map'
									src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2847.8704737997826!2d-123.12073754896937!3d49.28272967923086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486718b5b769eeb%3A0x322d29ae6f8c77!2s486%20Kentucky%20Street%2C%20Faerie%20Glen%2C%20Pretoria!5e0!3m2!1sen!2sza!4v1638985715214!5m2!1sen!2sza'
									width='100%'
									height='400'
									style={{ border: 0 }}
									allowFullScreen=''
									loading='lazy'></iframe>
							</div>
						</section>
					</div>
				</View>
			</View>
		);
	}
}
