import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native-web";
import BgLayer2 from "../components/BgLayer2";
import EnquireNow from "../components/EnquireNow";

const CivilPage = () => {
	const [enquireNowForm, setEnquireNowForm] = React.useState(false);

	return (
		<View style={{ flex: 1 }}>
			<BgLayer2 />

			<View
				style={{
					flex: 1,
					position:
						window.innerWidth < 1000 ? "relative" : "absolute",
					flexDirection: "row",
					// paddingTop: "20vh",
					width: "100%",
					// backgroundColor: "red",
					maxWidth: 1500,
					marginTop: 100,
				}}>
				<View
					style={{
						zIndex: 99,
						// backgroundColor: "red",
						paddingTop: window.innerWidth < 1000 ? 0 : 20,
						padding: window.innerWidth < 1000 ? 0 : 50,
						paddingLeft: "10%",
						flex: 1,
						width: "100%",
					}}>
					<h3 className='text-2xl sm:text-3xl md:text-4xl lg:text-4xl font-semibold py-2 text-left lg:text-left text-primary uppercase mb-10'>
						Services •
					</h3>
					<h1 className='text-5xl sm:text-5xl md:text-6xl lg:text-8xl font-bold leading-tight text-white text-left lg:text-left mb-5'>
						Civil Litigation
					</h1>
					<h1 className='text-5xl sm:text-5xl md:text-6xl lg:text-8xl font-bold leading-tight text-white text-left lg:text-left mb-10'>
						Legal Experience
					</h1>
					<p className='text-lg'>
						AH Stander & Agenbag Inc. provide personalized and
						competent legal advice and assistance on all aspects of
						Civil Litigation. Our lawyers will resolve the dispute,
						whether contractual or delictual, through appropriate
						dispute resolution mechanisms or through litigation in
						the Magistrate's Court or High Court.
					</p>
					<TouchableOpacity
						onPress={() => {
							setEnquireNowForm(!enquireNowForm);
						}}>
						<div className='flex justify-start md:justify-start'>
							<div
								href='contact-us'
								className='btn btn-custom btn-lg page-scroll mt-8 px-6 bg-primary text-white'
								style={{
									paddingLeft: 40,
									paddingRight: 40,
								}}>
								BOOK A CONSULTATION
							</div>
						</div>
					</TouchableOpacity>
				</View>
				<View style={{ flex: 1 }} />
			</View>

			{enquireNowForm ? (
				<EnquireNow close={() => setEnquireNowForm(false)} />
			) : null}
		</View>
	);
};

export default CivilPage;
