import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native-web";
import tick from "./tick.png";

export default class EmailInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			emailValid: false,
		};
		this._timeout = 500;
	}
	render() {
		return (
			<View>
				<TextInput
					id='user-text-field'
					type='email'
					style={{
						paddingLeft:
							this.props.textAlign === "center" ? 14 : 40,
						padding: 14,
						marginTop: 0,
						fontSize: 16,
						borderRadius: 15,
						textAlign: this.props.textAlign,
						color: "#000",
						fontWeight: this.state.emailValid ? "500" : "200",
						fontFamily: "Avenir",
						letterSpacing: 0.26,
						borderWidth: this.state.emailValid ? 0 : 0.3,
						backgroundColor: "#FFF",
						borderColor: "#000",
					}}
					placeholderTextColor={"#797979"}
					autoCapitalize='none'
					clearButtonMode='while-editing'
					autoComplete='email'
					autoCorrect={false}
					keyboardType='email-address'
					textContentType='emailAddress'
					placeholder={`Email `}
					// value={this.state.email}
					onChangeText={(text) => {
						text = text.toLocaleLowerCase();
						let reg =
							/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

						// clearTimeout(this._timeout);
						// this._timeout = setTimeout(() => {

						this.setState({
							email: text,
						});
						// }, 500);

						if (reg.test(text) === false) {
							this.setState({ emailValid: false });
							this.props.setParentState("");
						} else {
							this.setState({ emailValid: true });

							clearTimeout(this._timeout);
							this._timeout = setTimeout(() => {
								this.props.setParentState(text);
							}, 200);
						}
					}}
				/>

				<Image
					source={tick}
					style={{
						width: 16,
						height: 16,
						resizeMode: "contain",
						position: "absolute",
						left: 15,
						top: 18,
						opacity: this.state.emailValid ? 1 : 0.2,
					}}
				/>
			</View>
		);
	}
}
