import moment from "moment";
import React, { Component } from "react";
import { View, Text, ScrollView } from "react-native-web";
import { APP_COLOURS } from "../APP_VARS";
import Logo from "../LOGO";
// import Footer from "./Footer";
// import FooterMobile from "./FooterMobile";

export default class CookiePolicy extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let itemHeight = window.innerHeight * 0.4;
		itemHeight = itemHeight > 150 ? 150 : itemHeight;
		return (
			<View
				style={{
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: APP_COLOURS.BG4,
				}}>
				<ScrollView>
					<View
						style={{
							width: window.innerWidth,
							marginTop: 100,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								maxWidth: 800,
								width: "100%",
								padding: 15,
							}}>
							<View
								style={{
									// flex: 1,
									// justifyContent: "flex-start",
									alignItems: "flex-start",
									marginBottom: 40,
									// left: -90,
								}}>
								<Logo size={itemHeight} />
							</View>

							<Text
								style={{
									color: APP_COLOURS.DESELECTEDCOLOR,
								}}>
								<h1>Cookie Policy</h1>

								<h1>Definitions and key terms</h1>
								<p>
									To help explain things as clearly as
									possible in this Cookie Policy, every time
									any of these terms are referenced, are
									strictly defined as:
								</p>
								<ul>
									<li>
										Cookie: small amount of data generated
										by a website and saved by your web
										browser. It is used to identify your
										browser, provide analytics, remember
										information about you such as your
										language preference or login
										information.
									</li>
									<li>
										Company: when this policy mentions
										“Company,” “we,” “us,” or “our,” it
										refers to ahslaw.co.za, that is
										responsible for your information under
										this Cookie Policy.
									</li>
									<li>
										Device: any internet connected device
										such as a phone, tablet, computer or any
										other device that can be used to visit
										ahslaw.co.za and use the services.
									</li>
									<li>
										Personal Data: any information that
										directly, indirectly, or in connection
										with other information — including a
										personal identification number — allows
										for the identification or
										identifiability of a natural person.
									</li>
									<li>
										Service: refers to the service provided
										by ahslaw.co.za as described in the
										relative terms (if available) and on
										this platform.{" "}
									</li>
									<li>
										Third-party service: refers to
										advertisers, contest sponsors,
										promotional and marketing partners, and
										others who provide our content or whose
										products or services we think may
										interest you.
									</li>
									<li>
										Website: site, which can be accessed via
										this URL: acl.ahslaw.co.za
									</li>
								</ul>

								<h1>Introduction</h1>
								<p>
									This Cookie Policy explains how ahslaw.co.za
									and its affiliates (collectively
									"ahslaw.co.za", "we", "us", and "ours"), use
									cookies and similar technologies to
									recognize you when you visit our website,
									including without limitation
									acl.ahslaw.co.za and any related URLs,
									mobile or localized versions and related
									domains / sub-domains ("Websites"). It
									explains what these technologies are and why
									we use them, as well as the choices for how
									to control them.
								</p>

								<h1>What is a cookie?</h1>
								<p>
									A cookie is a small text file that is stored
									on your computer or other internet connected
									device in order to identify your browser,
									provide analytics, remember information
									about you such as your language preference
									or login information. They're completely
									safe and can't be used to run programs or
									deliver viruses to your device.
								</p>

								<h1>Why do we use cookies?</h1>
								<p>
									We use first party and/or third party
									cookies on our website for various purposes
									such as:
								</p>
								<ul>
									<li>
										To facilitate the operation and
										functionality of our website;
									</li>
									<li>
										To improve your experience of our
										website and make navigating around them
										quicker and easier;
									</li>
									<li>
										To allow us to make a bespoke user
										experience for you and for us to
										understand what is useful or of interest
										to you;
									</li>
									<li>
										To analyze how our website is used and
										how best we can customize it;
									</li>
									<li>
										To identify future prospects and
										personalize marketing and sales
										interactions with it;
									</li>
									<li>
										To facilitate the tailoring of online
										advertising to your interests.
									</li>
									<li>
										You: a person or entity that is
										registered with ahslaw.co.za to use the
										Services.
									</li>
								</ul>

								<h1>
									What type of cookies does ahslaw.co.za use?
								</h1>
								<p>
									Cookies can either be session cookies or
									persistent cookies. A session cookie expires
									automatically when you close your browser. A
									persistent cookie will remain until it
									expires or you delete your cookies.
									Expiration dates are set in the cookies
									themselves; some may expire after a few
									minutes while others may expire after
									multiple years. Cookies placed by the
									website you’re visiting are called “first
									party cookies”.
								</p>
								<p>
									Strictly Necessary cookies are necessary for
									our website to function and cannot be
									switched off in our systems. They are
									essential in order to enable you to navigate
									around the website and use its features. If
									you remove or disable these cookies, we
									cannot guarantee that you will be able to
									use our website.
								</p>
								<p>
									We use the following types of cookies in our
									website:
								</p>
								<h1>Essential Cookies</h1>
								<p>
									We use essential cookies to make our website
									work. These cookies are strictly necessary
									to enable core functionality such as
									security, network management, your cookie
									preferences and accessibility. Without them
									you wouldn't be able to use basic services.
									You may disable these by changing your
									browser settings, but this may affect how
									the Websites function.
								</p>
								<h1>Performance and Functionality Cookies</h1>
								<p>
									These cookies are used to enhance the
									performance and functionality of our website
									but are non-essential to their use. However,
									without these cookies, certain functionality
									like videos may become unavailable or you
									would be required to enter your login
									details every time you visit the website as
									we would not be able to remember that you
									had logged in previously.
								</p>
								<h1>Marketing Cookies</h1>
								<p>
									These account-based marketing cookies enable
									us to identify future prospects and
									personalize sales and marketing interactions
									with them.
								</p>
								<h1>Analytics and Customization Cookies</h1>
								<p>
									These cookies collect information that is
									used to help us understand how our website
									is being used or how effective our marketing
									campaigns are, or to help us customize our
									website for you.
								</p>
								<p>
									You can find more information about Cookies
									and Tracking Technologies at{" "}
									<a
										href='http://www.allaboutcookies.org'
										target='_blank'>
										www.allaboutcookies.org
									</a>{" "}
									and{" "}
									<a
										href='http://www.youronlinechoices.eu'
										target='_blank'>
										www.youronlinechoices.eu
									</a>
									.
								</p>
								<p>
									We use cookies served by Google Analytics to
									collect limited data directly from end-user
									browsers to enable us to better understand
									your use of our website. Further information
									on how Google collects and uses this data
									can be found
									at: https://www.google.com/policies/privacy/partners/.
									You can opt-out of all Google supported
									analytics on our Websites by
									visiting: https://tools.google.com/dlpage/gaoptout.
								</p>
								<h1>Social Media Cookies</h1>
								<p>
									These cookies are used when you share
									information using a social media sharing
									button or “like” button on our Site or you
									link your account or engage with our content
									on or through a social networking website
									such as Facebook, Twitter or Google+. The
									social network will record that you have
									done this. These cookies may also include
									certain code that has been placed on the
									platform to help track conversions from ads,
									optimize ads based on collected data, build
									targeted audiences for future ads, and
									remarket to qualified users who have already
									taken certain action on the platform.
								</p>
								<h1>How you can manage cookies?</h1>
								<p>
									Most browsers allow you to control cookies
									through their 'settings' preferences.
									However, if you limit the ability of
									websites to set cookies, you may worsen your
									overall user experience, since it will no
									longer be personalized to you. It may also
									stop you from saving customized settings
									like login information. Browser
									manufacturers provide help pages relating to
									cookie management in their products.
								</p>
								<p>
									Browser manufacturers provide help pages
									relating to cookie management in their
									products. Please see below for more
									information.
								</p>
								<ul>
									<li>Google Chrome</li>
									<li>Internet Explorer</li>
									<li>Mozilla Firefox</li>
									<li>Safari (Desktop)</li>
									<li>Safari (Mobile)</li>
									<li>Android Browser</li>
									<li>Opera</li>
									<li>Opera Mobile</li>
								</ul>

								<h1>
									Blocking and disabling cookies and similar
									technologies
								</h1>
								<p>
									Wherever you're located you may also set
									your browser to block cookies and similar
									technologies, but this action may block our
									essential cookies and prevent our website
									from functioning properly, and you may not
									be able to fully utilize all of its features
									and services. You should also be aware that
									you may also lose some saved information
									(e.g. saved login details, site preferences)
									if you block cookies on your browser.
									Different browsers make different controls
									available to you. Disabling a cookie or
									category of cookie does not delete the
									cookie from your browser, you will need to
									do this yourself from within your browser,
									you should visit your browser's help menu
									for more information.
								</p>

								<h1>Changes To Our Cookie Policy</h1>
								<p>
									We may change our Service and policies, and
									we may need to make changes to this Cookie
									Policy so that they accurately reflect our
									Service and policies. Unless otherwise
									required by law, we will notify you (for
									example, through our Service) before we make
									changes to this Cookie Policy and give you
									an opportunity to review them before they go
									into effect. Then, if you continue to use
									the Service, you will be bound by the
									updated Cookie Policy. If you do not want to
									agree to this or any updated Cookie Policy,
									you can delete your account.
								</p>

								<h1>Your Consent</h1>
								<p>
									By using our website, registering an
									account, or making a purchase, you hereby
									consent to our Cookie Policy and agree to
									its terms.
								</p>
								<h1>Contact Us</h1>
								<p>
									Don't hesitate to contact us if you have any
									questions regarding our Cookie Policy.
								</p>

								<p>Updated at 2024-04-01}</p>
								<ul>
									<li>
										Via Email:{" "}
										<a
											href='mailto:info@ahslaw.co.za'
											class='__cf_email__'
											data-cfemail='660f08000926120312140f0503480509481c07'>
											info@tetrice.co.za
										</a>
									</li>
								</ul>
								<script
									data-cfasync='false'
									src='/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js'></script>
							</Text>
						</View>
					</View>
					{/* {window.innerWidth < 700 ? (
						<FooterMobile
							h={window.innerHeight}
							w={window.innerWidth}
							enireNow={() => {}}
						/>
					) : (
						<View
							style={{
								width: "100%",
							}}>
							<Footer
								h={this.props.screenHeight}
								w={window.innerWidth}
								showEnquireNow={false}
								enireNow={() =>
									this.setState({
										enquireNowForm: true,
									})
								}
							/>
						</View>
					)} */}
				</ScrollView>
			</View>
		);
	}
}
