// src/pages/HomePage.jsx
import React from "react";
import Header from "../components/Header";
import Services from "../components/Services";
import ContactBanner from "../components/ContactBanner";
import MeetTheTeam from "./MeetTheTeam";

const HomePage = ({ landingPageData }) => {
	return (
		<>
			<Header data={landingPageData.Header} />
			<Services data={landingPageData.Services} />
			<MeetTheTeam data={landingPageData.team} />
			<ContactBanner />
		</>
	);
};

export default HomePage;
