import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native-web";
import Logo from "../LOGO";
import { ReactSVG } from "react-svg";
import EnquireNow from "./EnquireNow";
import BgLayer from "./BgLayer";
// import CookiePolicy from "./CookiePolicy";

export default class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			enquireNowForm: false,
			bgImage: null,
			windowWidth: window.innerWidth,
		};
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
		this.handleResize(); // Initial call to set the image based on initial screen size
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize() {
		this.setState({
			windowWidth: window.innerWidth,
		});
	}

	render() {
		let { windowWidth } = this.state;
		if (windowWidth <= 1000) {
			return (
				<View
					style={{
						// flex: 1,
						// padding: 30,
						// paddingTop: 100,
						height: window.innerHeight,
						// width: window.innerWidth,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<BgLayer />
					<View
						style={{
							// flex: 1,
							height: window.innerHeight / 2,
							width: "100%",
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								zIndex: 99,
								backgroundColor: "#000",
								padding: "5%",
								// flex: 1,
								justifyContent: "center",
								alignItems: "center",
								borderRadius: 15,
								position: "absolute",
								bottom: 10,
								paddingBottom: "10%",
							}}>
							<h3 className='text-2xl sm:text-3xl md:text-4xl lg:text-4xl font-semibold py-2 text-left lg:text-left text-primary uppercase mb-10'>
								Attorneys • Notaries • Conveyancers
							</h3>
							<h1 className='text-5xl sm:text-5xl md:text-6xl lg:text-8xl font-bold leading-tight text-white text-left lg:text-left mb-0'>
								Legal advice and
							</h1>
							<h1 className='text-5xl sm:text-5xl md:text-6xl lg:text-8xl font-bold leading-tight text-white text-left lg:text-left mb-5'>
								expertise you can trust
							</h1>
							<TouchableOpacity
								onPress={() => {
									this.setState({
										enquireNowForm:
											!this.state.enquireNowForm,
									});
								}}>
								<div className='flex justify-start md:justify-start'>
									<div
										className='btn btn-custom btn-lg page-scroll mt-8 px-6 bg-primary text-white'
										style={{
											paddingLeft: 40,
											paddingRight: 40,
										}}>
										BOOK A CONSULTATION
									</div>
								</div>
							</TouchableOpacity>
						</View>
					</View>

					{this.state.enquireNowForm ? (
						<EnquireNow
							{...this.props}
							{...this.state}
							nextPage={() =>
								this.setState({
									resultsPage: true,
								})
							}
							close={() =>
								this.setState({
									enquireNowForm: false,
								})
							}
						/>
					) : null}

					{/* {this.cookieConcent()} */}
				</View>
			);
		} else {
			return (
				<View
					style={{
						flex: 1,
						// padding: 30,
						// paddingTop: 100,
						// height: window.innerHeight,
						// width: window.innerWidth,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<BgLayer />
					<View
						style={{
							flex: 1,
							position: "absolute",
							flexDirection: "row",
							// paddingTop: "20vh",
							width: "100%",
							// backgroundColor: "red",
							// maxWidth: 1500,
						}}>
						<View
							style={{
								zIndex: 99,

								// paddingTop: 20,
								// padding: 50,
								paddingLeft: "10%",
								paddingRight: 0,
								flex: 1,
							}}>
							<h3 className='text-2xl sm:text-3xl md:text-4xl lg:text-4xl font-semibold py-2 text-left lg:text-left text-primary uppercase mb-10'>
								Attorneys • Notaries • Conveyancers
							</h3>
							<h1 className='text-5xl sm:text-5xl md:text-6xl lg:text-8xl font-bold leading-tight text-white text-left lg:text-left '>
								Legal advice and
							</h1>
							<h1 className='text-5xl sm:text-5xl md:text-6xl lg:text-8xl font-bold leading-tight text-white text-left lg:text-left mb-10'>
								expertise you can trust
							</h1>
							<TouchableOpacity
								onPress={() => {
									this.setState({
										enquireNowForm:
											!this.state.enquireNowForm,
									});
								}}>
								<div className='flex justify-start md:justify-start'>
									<div
										href='contact-us'
										className='btn btn-custom btn-lg page-scroll mt-8 px-6 bg-primary text-white'
										style={{
											paddingLeft: 40,
											paddingRight: 40,
										}}>
										BOOK A CONSULTATION
									</div>
								</div>
							</TouchableOpacity>
						</View>
						<View
							style={{
								flex: 1,
							}}
						/>
					</View>

					{this.state.enquireNowForm ? (
						<EnquireNow
							{...this.props}
							{...this.state}
							nextPage={() =>
								this.setState({
									resultsPage: true,
								})
							}
							close={() =>
								this.setState({
									enquireNowForm: false,
								})
							}
						/>
					) : null}

					{/* {this.cookieConcent()} */}
				</View>
			);
		}
	}

	loadingComponent = () => {
		return (
			<View
				style={{
					flex: 1,
					zIndex: 999,
					height: window.innerHeight,
					width: window.innerWidth,
					position: "absolute",
					top: 0,
					left: 0,
				}}>
				<div
					className='Bg'
					style={{
						height: window.innerHeight,
						width: window.innerWidth,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							height: window.innerHeight,
							width: window.innerWidth,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Logo size={60} />
					</View>
				</div>
			</View>
		);
	};
}
