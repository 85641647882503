// src/App.jsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import CivilPage from "./pages/CivilPage";
import FamilyLawPage from "./pages/FamilyLawPage";
import NotFoundPage from "./pages/NotFoundPage";
import CriminalLawPage from "./pages/CriminalLawPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import CookiePolicy from "./components/CookiePolicy";
// Example data, replace with your actual data
const landingPageData = {
	Header: {
		/* Your Header data */
	},
	Services: {
		/* Your Services data */
	},
	team: [
		{
			name: "ADRIAAN HENDRIKUS STANDER",
			title: "EXECUTIVE DIRECTOR",
			desc: "Mr Rikus Stander obtained his LLB degree through the University of South Africa in 2012...",
			img: "/img/08/Rikus-Stander-683x1024.jpg",
		},
		{
			name: "GERT AGENBAG",
			title: "DIRECTOR",
			desc: "	Mr Gert Agenbag is a Director at AH Stander & Agenbag Inc. and practices extensively...",
			img: "/img/08/Gert-Agenbag-683x1024.jpg",
		},
		{
			name: "CHARL-MARI VAN WYK",
			title: "ATTORNEY",
			desc: "	Ms Charl-Mari van Wyk is an attorney at AH Stander Agenbag Incorporated and practices...",
			img: "/img/08/Charl-Mari-Van-Wyk-683x1024.jpg",
		},
		{
			name: "CHRISTIAAN JANSE VAN RENSBURG",
			title: "CANDIDATE ATTORNEY",
			desc: " Mr. Christiaan Janse van Rensburg is a candidate attorney who started with his articles...",
			img: "/img/08/Christiaan-Jansen-Van-Rensburg-768x1024.jpg",
		},
		{
			name: "CHANEL LOUW",
			title: "CANDIDATE ATTORNEY",
			desc: "  Ms. Chanel Louw is a Candidate Attorney at AH Stander Agenbag Inc. Ms. Louw completed...",
			img: "/img/08/Chanel-Louw-900x1024.jpg",
		},
		{
			name: "JAEN-MARI SCHEUN-ABBOTT",
			title: "PARALEGAL",
			desc: "Ms. Jaen-Mari Scheun-Abbott joined our firm in March 2022 and is a spearhead in our...",
			img: "/img/08/Jaen-Mari-Scheun-Abbott-768x1024.jpg",
		},
	],
};

function App() {
	return (
		<Router>
			<Navigation />
			<main className='flex-grow'>
				<Routes>
					<Route
						path='/'
						element={<HomePage landingPageData={landingPageData} />}
					/>
					<Route path='/civil-litigation' element={<CivilPage />} />
					<Route path='/family-law' element={<FamilyLawPage />} />
					<Route
						path='/criminal-defense'
						element={<CriminalLawPage />}
					/>
					<Route
						path='/about-us'
						element={
							<AboutPage landingPageData={landingPageData} />
						}
					/>
					<Route path='/contact-us' element={<ContactPage />} />
					<Route path='*' element={<NotFoundPage />} />{" "}
					<Route path='/cookiepolicy' element={<CookiePolicy />} />
					{/* Catch-all route for 404 */}
				</Routes>
			</main>
			<Footer />
		</Router>
	);
}

export default App;
