import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native-web";
import Font from "react-font";
import Fade from "react-reveal/Fade";

export default class CellNumber extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPolicy: false,
		};
	}

	render() {
		let w = this.props.screenWidth;
		let h = this.props.screenHeight;
		let doc = [];
		return (
			<View
				style={{
					flex: 1,
					flexDirection: "row",
					// alignItems: "center",
				}}>
				<TouchableOpacity
					onPress={() => {
						this.setState({
							agreed: !this.state.agreed,
						});
						this.props.setParentState(!this.state.agreed);
					}}
					style={{
						marginRight: 5,
					}}>
					{this.state.agreed ? (
						<Image
							source={require("./checked.png")}
							style={{
								width: 16,
								height: 16,
								resizeMode: "contain",
							}}
						/>
					) : (
						<Image
							source={require("./unchecked.png")}
							style={{
								width: 16,
								height: 16,
								resizeMode: "contain",
							}}
						/>
					)}
				</TouchableOpacity>

				<TouchableOpacity
					style={{
						width: "100%",
						flexDirection: "row",
						alignItems: "center",
						flexWrap: "wrap",
					}}
					onPress={() => {
						this.setState({
							// showPolicy: true,
						});
					}}>
					<Text
						style={{
							alignItems: "center",
							justifyContent: "center",
							color: "#000",
							fontSize: 14,
						}}>
						<Font weight={300} family='Montserrat'>
							I would like to recieve promotional material such as
							news letters. (optional)
						</Font>
					</Text>
				</TouchableOpacity>

				<Modal
					transparent={true}
					visible={this.state.showPolicy}
					animationType='fade'
					style={{
						width: w,
						height: h,
					}}>
					<TouchableOpacity
						style={{
							width: w,
							height: h,
						}}
						onPress={() =>
							this.setState({
								showPolicy: false,
							})
						}>
						<View
							style={{
								// flex: 1,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "rgba(52, 52, 52, 0.7)",
								width: w,
								height: h,
							}}></View>
					</TouchableOpacity>
					<View
						style={{
							// flex: 1,
							alignItems: "center",
							justifyContent: "center",
							width: w,
							height: h,
							position: "absolute",
							zIndex: 999,
						}}>
						<View
							style={{
								// position: "absolute",
								width: w,
								maxWidth: 450,
								// minHeight: 300,
								height: h,
								maxHeight: 600,
								// backgroundColor: "#E8E8E8",
								backgroundColor: "#FFF",
								borderRadius: 35,
								justifyContent: "center",
								alignItems: "center",
								padding: 18,
								paddingBottom: 0,
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 2,
								},
								shadowOpacity: 0.25,
								shadowRadius: 7.84,
								elevation: 12,
								zIndex: 999,
							}}>
							<ScrollView
								contentContainerStyle={{
									paddingRight: 8,
								}}>
								<Text
									style={{
										marginBottom: 20,
										fontWeight: "500",
										fontSize: 20,
									}}></Text>
								<Text
									adjustsFontSizeToFit
									style={{
										color: "#000",
										fontSize: 17,
										margin: 10,
										marginBottom: 20,
									}}>
									<Font weight={500} family='Montserrat'>
										Terms and conditions
									</Font>
								</Text>

								{doc.map((d, i) => (
									<Fade>
										<View
											key={i}
											style={
												{
													// marginBottom: 10,
												}
											}>
											<Text
												style={{
													marginBottom: 10,
													fontWeight: "300",
													fontSize: 12,
												}}>
												{d}
											</Text>
										</View>
									</Fade>
								))}
								<Fade>
									<TouchableOpacity
										style={{
											width: "100%",
											marginBottom: 20,
											marginTop: 20,
										}}
										onPress={async () => {
											this.setState({
												showPolicy: false,
												agreed: true,
											});
										}}>
										<View
											style={{
												backgroundColor: "#000",
												width: "100%",
												height: 45,
												borderRadius: 25,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													alignItems: "center",
													justifyContent: "center",
													color: "#FFF",
													fontSize: 15,
												}}>
												<Font
													weight={700}
													family='Montserrat'>
													Accept & Next
												</Font>
											</Text>
										</View>
									</TouchableOpacity>
								</Fade>
							</ScrollView>
						</View>
					</View>
				</Modal>
			</View>
		);
	}
}
