import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import CookieConsent from "react-cookie-consent";
import { APP_COLOURS } from "../APP_VARS";
import Logo from "../LOGO";

export default class Navigation extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<nav id='menu' className='navbar navbar-default navbar-fixed-top'>
				<div className='container'>
					<div className='navbar-header'>
						{/* <button
							type='button'
							className='navbar-toggle collapsed'
							data-toggle='collapse'
							data-target='#bs-example-navbar-collapse-1'>
							<span className='sr-only'>Toggle navigation</span>
							<span className='icon-bar'></span>
							<span className='icon-bar'></span>
							<span className='icon-bar'></span>
						</button> */}
						<a className='navbar-brand page-scroll' href='/'>
							<img
								src='/img/08/logo-03-1.png'
								alt='Logo'
								className='navbar-logo'
							/>
						</a>
					</div>

					{/* <div
						className='collapse navbar-collapse'
						id='bs-example-navbar-collapse-1'>
						<ul className='nav navbar-nav navbar-right'>
							<li>
								<a href='#services' className='page-scroll'>
									Services
								</a>
							</li>
							<li>
								<a href='#contact' className='page-scroll'>
									Contact
								</a>
							</li>
						</ul>
					</div> */}
				</div>
				{this.cookieConcent()}
			</nav>
		);
	}

	cookieConcent = () => {
		return (
			<CookieConsent
				location='bottom'
				buttonText='Accept & continue'
				cookieName='CookieNotice'
				style={{
					// background: APP_COLOURS.BG2,
					backgroundColor: APP_COLOURS.BLUE,
				}}
				buttonStyle={{
					color: "#FFF",
					fontSize: "16px",
					borderRadius: 10,
					padding: 10,
					// backgroundColor: APP_COLOURS.BLUE,
					backgroundColor: APP_COLOURS.BG5,
				}}
				expires={150}>
				<View
					style={{
						flexDirection: "row",
						padding: 20,
					}}>
					{/* <View
						style={{
							alignItems: "flex-start",
							// marginBottom: 40,
							marginRight: 15,
						}}>
						<Logo size={90} />
					</View> */}
					<Text
						style={{
							color: "#FFF",
							maxWidth: 600,
						}}>
						We use cookies to make your experience of our websites
						better. By using and further navigating this website you
						accept this. Detailed information about the use of
						cookies on this website is available by clicking on{" "}
						<TouchableOpacity
							style={{
								color: "#FFF",
								fontWeight: "600",
							}}
							onPress={() => {
								window.open(
									"https://tetrice.co.za/cookiepolicy",
									"blank",
								);
							}}>
							<Text
								style={{
									color: "#FFF",
									fontWeight: "800",
								}}>
								more information
							</Text>
						</TouchableOpacity>
					</Text>
				</View>
			</CookieConsent>
		);
	};
}
