import React, { useRef, useState } from "react";
import { View, Text, ScrollView, StyleSheet, Image } from "react-native-web";
import { ReactSVG } from "react-svg";
import dot from "../images/dot.svg";

const AboutPage = ({ data }) => {
	const scrollRef = useRef(null);

	const scrollLeft = () => {
		scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
	};

	const scrollRight = () => {
		scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
	};

	return (
		<div className='bg-gray-900 text-gray-100 p-6'>
			<View style={{}}>
				<View
					style={{
						width: "100%",
						padding: "2%",
					}}>
					<Text
						style={{
							fontSize: 35,
							color: "#FFF",
							fontWeight: "600",
						}}>
						Meet the team
					</Text>
				</View>

				<div className='relative'>
					{/* Scrollable View */}
					<View
						style={{
							position: "absolute",
							left: 0,
							bottom: 0,
						}}>
						<ReactSVG src={dot} />
					</View>
					<ScrollView
						horizontal
						showsHorizontalScrollIndicator={false}>
						<View
							style={{
								width: "20%",
								display:
									window.innerWidth < 1000 ? "none" : "flex",
							}}
						/>
						{data.map((d, index) => (
							<HoverableCard key={index} data={d} />
						))}
						<View
							style={{
								width: "20%",
								display:
									window.innerWidth < 1000 ? "none" : "flex",
							}}
						/>
					</ScrollView>

					{/* <button
						className='absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-md'
						onClick={scrollLeft}>
						&#10094;
					</button>
					<button
						className='absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-md'
						onClick={scrollRight}>
						&#10095;
					</button> */}
				</div>
			</View>
		</div>
	);
};

const HoverableCard = ({ data }) => {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<View
			className='cardContainer'
			style={[
				styles.cardContainer,
				isHovered ? { ...styles.hovered } : {},
			]}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}>
			<View style={styles.imageContainer}>
				{/* <img
					// className='teamImage'
					src={data.img}
					alt={data.name}
					style={styles.image}
				/> */}
				<Image source={data.img} alt={data.name} style={styles.image} />
			</View>
			<View style={{ width: "100%" }}>
				<div className='text-center mt-4'>
					<h3 className='text-xl font-semibold'>{data.name}</h3>
					<h5
						style={{
							fontSize: 19,
							fontWeight: "600",
							color: "#000",
						}}>
						{data.title}
					</h5>
					<p
						style={{
							fontSize: 15,
							fontWeight: "400",
							color: "#000",
							marginTop: 4,
							opacity: 0.7,
						}}>
						{data.desc}
					</p>
				</div>
			</View>
		</View>
	);
};

export default AboutPage;

const styles = StyleSheet.create({
	cardContainer: {
		marginRight: 20,
		backgroundColor: "#FFF",
		padding: 10,
		borderRadius: 14,
		width: 300,
		height: 500,
		margingBottom: 50,
		transition: "transform 0.3s ease, box-shadow 0.3s ease",
		cursor: "pointer",
		overflow: "hidden",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		transform: "scale(0.98)",
	},
	imageContainer: {
		width: "100%",
		height: 350,
		overflow: "hidden",
		borderRadius: 10,
	},
	image: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
		// transition: "transform 0.3s ease",
	},
	hovered: {
		transform: "scale(1.00)",
		boxShadow: "0 5px 15px rgba(0,0,0,0.2)",
	},
});
