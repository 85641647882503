import React, { useState } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	Modal,
} from "react-native-web";
import Font from "react-font";

import moment from "moment";

const initialState = {
	name: "",
	email: "",
	message: "",
};
const Footer = (props) => {
	const [{ name, email, message }, setState] = useState(initialState);

	const clearState = () => setState({ ...initialState });

	return (
		<div className='footer-container'>
			<div className='container'>
				<div className='row'>
					{/* First Column */}
					<div className='col-md-4 footer-col'>
						<div className='footer-image'>
							<img
								src={require("../logo.png")}
								alt='Footer Image'
							/>
						</div>
						<div className='footer-social'>
							<a href={props.data ? props.data.facebook : "#"}>
								<i className='fa fa-facebook'></i> Facebook
							</a>
							<a href={props.data ? props.data.linkedin : "#"}>
								<i className='fa fa-linkedin'></i> LinkedIn
							</a>
						</div>
					</div>

					{/* Second Column */}
					<div className='col-md-4 footer-col'>
						<Text
							style={{
								fontSize: 16,
								fontWeight: "600",
								color: "#FFF",
								marginBottom: 10,
							}}>
							Quick links
						</Text>
						<ul
							style={{
								opacity: 0.8,
							}}>
							<li>
								<a href='/'>HOME</a>
							</li>
							<li>
								<a href='/civil-litigation'>CIVIL LITIGATION</a>
							</li>
							<li>
								<a href='/criminal-defense'>CRIMINAL LAW</a>
							</li>
							<li>
								<a href='/family-law'>FAMILY LAW</a>
							</li>
							<li>
								<a href='/about-us'>ABOUT US</a>
							</li>
							<li>
								<a href='/contact-us'>CONTACT US</a>
							</li>
						</ul>
					</div>

					{/* Third Column */}

					<div className='col-md-4 footer-col'>
						<View
							style={{
								marginBottom: 10,
							}}>
							<h4>URGENT BAIL LINE</h4>
							<p
								style={{
									opacity: 0.8,
								}}>
								<a href='tel:0798404717'>079 840 4717</a>
								<br />
								<a href='tel:0798981454'>079 898 1454</a>
							</p>
						</View>
						<View
							style={{
								marginBottom: 10,
							}}>
							<h4>CONTACT US</h4>
							<p
								style={{
									opacity: 0.8,
								}}>
								<a href='mailto:rs@ahslaw.co.za'>
									rs@ahslaw.co.za
								</a>
								<br />
								<a href='tel:0129912875'>012 991 2875</a>
							</p>
						</View>
					</div>
				</div>
			</div>

			<View
				style={{
					width: "100%",
					height: 60,
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "row",
					borderTopWidth: 0.5,
					// backgroundColor: "rgba(18, 23, 36,1)",
				}}>
				<Text
					style={{
						fontSize: 12,
						color: "#FFF",
						opacity: 0.8,
					}}>
					© {moment().format("YYYY")} All Rights Reserved. Powered by{" "}
				</Text>
				<Text
					style={{
						fontSize: 12,
						paddingTop: 1,
					}}>
					<a
						style={{
							textDecoration: "none",
							marginTop: 20,
							color: "#FFF",
						}}
						target='_blank'
						rel='noreferrer'
						href='https://www.tetrice.co.za'>
						<Font weight={800} family='Poppins'>
							tetrice
						</Font>
					</a>
				</Text>
			</View>
		</div>
	);
};

export default Footer;
