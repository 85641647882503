import React, { Component } from "react";
import { View, Text, Image } from "react-native-web";

import logo_notext from "./logo.png";

class Logo extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<Image
					source={logo_notext}
					style={{
						width: this.props.size * 0.8,
						height: this.props.size * 0.8,
						resizeMode: "contain",
					}}
				/>
			</View>
		);
	}
}

export default Logo;
