import React, { Component, useState } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native-web";
import BgLayer2 from "../components/BgLayer2";
import EnquireNow from "../components/EnquireNow";

const CriminalLawPage = () => {
	const [activeTab, setActiveTab] = useState(null);
	const [enquireNowForm, setEnquireNowForm] = useState(false);

	const toggleTab = (index) => {
		setActiveTab(activeTab === index ? null : index);
	};

	return (
		<View
			style={{
				flex: 1,
			}}>
			<BgLayer2 />

			<View
				style={{
					flex: 1,
					position:
						window.innerWidth < 1000 ? "relative" : "absolute",
					flexDirection: "row",
					// paddingTop: "20vh",
					width: "100%",
					// backgroundColor: "red",
					maxWidth: 1500,
					marginTop: 100,
				}}>
				<View
					style={{
						zIndex: 99,
						// backgroundColor: "red",
						paddingTop: window.innerWidth < 1000 ? 0 : 20,
						padding: window.innerWidth < 1000 ? 0 : 50,
						paddingLeft: "10%",
						flex: 1,
						width: "100%",
					}}>
					<h3 className='text-2xl sm:text-3xl md:text-4xl lg:text-4xl font-semibold py-2 text-left lg:text-left text-primary uppercase mb-10'>
						Services •
					</h3>
					<h1 className='text-5xl sm:text-5xl md:text-6xl lg:text-8xl font-bold leading-tight text-white text-left lg:text-left mb-10'>
						Criminal Law
					</h1>
					<View
						style={{
							flex: 1,
							// flexDirection: "row"
						}}>
						<View
							style={{
								// flex: 1,
								maxWidth: 600,
								marginRight: "5%",
								marginBottom: "5%",
							}}>
							<p className='text-lg'>
								AH Stander &amp; Agenbag Attorneys is a firm
								consisting of competent experienced Defence
								Attorneys. We are ready and willing to assist!
							</p>

							<TouchableOpacity
								onPress={() => {
									setEnquireNowForm(!enquireNowForm);
								}}>
								<div className='flex justify-start md:justify-start'>
									<div
										href='contact-us'
										className='btn btn-custom btn-lg page-scroll mt-8 px-6 bg-primary text-white'
										style={{
											paddingLeft: 40,
											paddingRight: 40,
										}}>
										BOOK A CONSULTATION
									</div>
								</div>
							</TouchableOpacity>
						</View>
						<View
							style={{
								flex: 1,
								maxWidth: 600,
								marginRight: "5%",
							}}>
							<h3 className='text-5xl sm:text-5xl md:text-6xl lg:text-5xl font-bold leading-tight text-white text-left lg:text-left mb-5'>
								CRIMINAL PROCEDURE
							</h3>
							<p className='text-lg mb-4'>
								In terms of the Constitution of the Republic of
								South Africa everyone is equal before the law
								and has the right to equal protection and
								benefit of the law. The Constitution further
								guarantees the right of every accused to a fair
								trial, which includes the right to be presumed
								innocent, to remain silent, not to testify
								during the proceedings and to adduce and
								challenge evidence.
							</p>
							<p className='text-lg mb-4'>
								Another important right of every accused is the
								right to a speedy trial. An application in terms
								of Section 342A of the Criminal Procedure Act
								may provide appropriate relief for an accused
								and result in a stay of the proceedings if such
								an application is based on the right facts and
								under the right circumstances.
							</p>
							<p className='text-lg mb-4'>
								There is little purpose however, for accused to
								have such rights, if they haven’t briefed an
								experienced Trial or Defence Attorney who is
								capable of practically enforcing and protecting
								such rights. AH Stander &amp; Agenbag Attorneys
								is just such a firm where you can be rest
								assured your rights will be protected.
							</p>

							{/* Accordion Section */}
							<div className='accordion'>
								<div className='accordion-item border border-gray-300 rounded mb-2'>
									<button
										className='w-full text-left px-4 py-2 font-semibold'
										onClick={() => toggleTab(0)}>
										PROCEDINGS BEFORE TRIAL
									</button>
									<div
										className={`accordion-content px-4 py-2 ${
											activeTab === 0 ? "block" : "hidden"
										}`}>
										<p>
											After you are arrested your trial
											will not start immediately. If you
											are being detained you will have to
											consider bringing an Application to
											be released on Bail. After Bail is
											granted or refused the matter might
											be postponed several times for
											“further investigation”. It is at
											this stage where a well-argued
											Section 342A application may provide
											the appropriate relief and stop any
											injustices in its tracks. You will
											also be given the opportunity to
											request disclosure from the state to
											determine the strength of its case
											and request further particulars with
											regards to the charges against you.
											Preferably this should be done after
											consultation with your legal
											representative and with his
											assistance. At this stage your
											appointed chosen Defence Attorney
											from AH Stander &amp; Agenbeg Inc.
											will formulate a trial strategy.
										</p>
									</div>
								</div>

								<div className='accordion-item border border-gray-300 rounded mb-2'>
									<button
										className='w-full text-left px-4 py-2 font-semibold'
										onClick={() => toggleTab(1)}>
										THE TRIAL
									</button>
									<div
										className={`accordion-content px-4 py-2 ${
											activeTab === 1 ? "block" : "hidden"
										}`}>
										<p>
											It is most definitely in any accused
											best interest to be represented by
											an experienced and competent Trial
											Attorney during this stage of the
											prosecution. AH Stander &amp;
											Agenbag Inc. we will ensure that
											every legal factor is carefully
											scrutinised and matched against the
											facts of your particular matter. A
											Legal Strategy will be formulated
											for your defence and same will
											executed with commitment, excellence
											and confidence.
										</p>
										<p>
											Do not fall in the trap of
											appointing an attorney not conversed
											with Trial and Criminal Defence. You
											need a special type of Attorney who
											is willing to descend the trenches
											and defend you with his whole being.
										</p>
									</div>
								</div>
							</div>
						</View>
					</View>
				</View>
				{/* <View
					style={{
						flex: 1,
					}}
				/> */}
			</View>

			{enquireNowForm ? (
				<EnquireNow close={() => setEnquireNowForm(false)} />
			) : null}
		</View>
	);
};

export default CriminalLawPage;
