import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image,
	Modal,
} from "react-native-web";
import moment from "moment";
import Font from "react-font";
import Fade from "react-reveal/Fade";
import CellNumber from "./CellNumber";
import EmailInput from "./EmailInput";
import FullName from "./FullName";
import DateOfBirth from "./DateOfBirth";
import Policy from "./Policy";
import OptIn from "./OptIn";

import "../../index.css";
import { APP_COLOURS } from "../../APP_VARS";

export default class EnquireNow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			first_name: "",
			last_name: "",
			cell: "",
			email: "",
			policy: false,
			successful: false,
		};
	}

	render() {
		return (
			<Modal visible animationType='fade' transparent>
				<View
					style={{
						width: window.innerWidth,
						height: window.innerHeight,
						backgroundColor: "rgba(52, 52, 52, 0.6)",
						position: "absolute",
						zIndex: 999,
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
					}}>
					<Fade>
						<ScrollView
							contentContainerStyle={{
								padding: window.innerWidth < 1000 ? 0 : "5%",
							}}>
							<View
								style={{
									width: "100%",
									// backgroundColor: "#FFF",
									backgroundColor: "#F7F7F7",
									borderRadius: 20,
									padding: 15,
									maxWidth: 500,
									marginRight: "auto",
									marginLeft: "auto",
								}}>
								<View
									style={{
										position: "absolute",
										zIndex: 99,
										top: 10,
										left: 10,
									}}>
									<TouchableOpacity
										onPress={() => {
											this.props.close();
										}}>
										<Image
											source={require("./close.png")}
											style={{
												width: 30,
												height: 30,
												resizeMode: "contain",
											}}
										/>
									</TouchableOpacity>
								</View>

								<div
									style={{
										borderRadius: 10,
										marginBottom: 15,
										backgroundColor: "#000",
									}}>
									<View
										style={{
											width: "100%",
											justifyContent: "center",
											alignItems: "center",
											borderRadius: 5,
										}}>
										<Image
											source={require("../../logo.png")}
											style={{
												width: "80%",
												height: 60,
												resizeMode: "contain",
												margin: 9,
											}}
										/>
									</View>
								</div>

								<View
									style={{
										marginBottom: 5,
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
										marginBottom: 20,
									}}>
									<Text
										style={{
											color: "#000",
											fontSize: 19,
											marginBottom: 15,
										}}>
										<Font weight={700} family='Montserrat'>
											Enquire now
										</Font>
									</Text>
									<Text
										style={{
											color: "#000",
											fontSize: 13,
											opacity: 0.7,
										}}>
										<Font weight={500} family='Montserrat'>
											Please fill in the required details.
										</Font>
									</Text>
								</View>

								<View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											// marginBottom: 15,
											padding: 10,
										}}>
										{/* <View
									style={
										{
											// justifyContent: "center",
										}
									}>
									<View
										style={{
											flexDirection: "row",
											marginTop: 5,
											// padding: 10,
											alignItems: "center",
											justifyContent: "center",
											// paddingLeft: 16,
											width: "100%",
										}}>
										<Text
											style={{
												color: "#000",
												fontSize: 12,
											}}>
											<Font weight={500} family='Montserrat'>
												Full Name
											</Font>
										</Text>
									</View>
								</View> */}

										<FullName
											first_name={(d) =>
												this.setState({ first_name: d })
											}
											last_name={(d) =>
												this.setState({ last_name: d })
											}
										/>
									</div>
								</View>
								<View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											// padding: 10,
											// maxWidth: 380,
											marginBottom: 25,
										}}>
										{/* <View
									style={
										{
											// justifyContent: "center",
										}
									}>
									<View
										style={{
											flexDirection: "row",
											marginTop: 5,
											// padding: 10,
											alignItems: "center",
											justifyContent: "center",
											// paddingLeft: 16,
											width: "100%",
										}}>
										<Text
											style={{
												color: "#000",
												fontSize: 12,
											}}>
											<Font weight={500} family='Montserrat'>
												Gender
											</Font>
										</Text>
									</View>
								</View> */}
										{/* <View
										style={{
											width: "100%",
											flexDirection: "row",
										}}>
										<TouchableOpacity
											style={{
												flex: 1,
												margin: 10,
											}}
											onPress={async () => {
												this.setState({
													gender: "male",
												});
											}}>
											<View
												style={{
													backgroundColor:
														this.state.gender === "male"
															? "#000"
															: "#FFF",
													borderWidth: 0.3,
													borderColor: "#000",
													height: "auto",
													padding: 12,
													borderRadius: 12,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														alignItems: "center",
														justifyContent: "center",
														color:
															this.state.gender === "male"
																? "#FFF"
																: "#000",
														fontSize: 15,
													}}>
													<Font
														weight={
															this.state.gender === "male"
																? 700
																: 500
														}
														family='Montserrat'>
														Male
													</Font>
												</Text>
											</View>
										</TouchableOpacity>
										<TouchableOpacity
											style={{
												flex: 1,
												margin: 10,
											}}
											onPress={async () => {
												this.setState({
													gender: "female",
												});
											}}>
											<View
												style={{
													backgroundColor:
														this.state.gender === "female"
															? "#000"
															: "#FFF",
													borderWidth: 0.3,
													borderColor: "#000",
													height: "auto",
													padding: 12,
													borderRadius: 12,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														alignItems: "center",
														justifyContent: "center",
														color:
															this.state.gender === "female"
																? "#FFF"
																: "#000",
														fontSize: 15,
													}}>
													<Font
														weight={
															this.state.gender === "female"
																? 700
																: 500
														}
														family='Montserrat'>
														Female
													</Font>
												</Text>
											</View>
										</TouchableOpacity>
									</View> */}
									</div>
								</View>
								<View
									style={{
										justifyContent: "center",
										// maxWidth: 280,
										marginBottom: 25,
										padding: 10,
									}}>
									{/* <View
								style={
									{
										// justifyContent: "center",
									}
								}>
								<View
									style={{
										flexDirection: "row",
										marginTop: 5,
										// padding: 10,
										alignItems: "center",
										justifyContent: "center",
										// paddingLeft: 16,
										width: "100%",
									}}>
									<Text
										style={{
											color: "#000",
											fontSize: 12,
										}}>
										<Font weight={500} family='Montserrat'>
											Email
										</Font>
									</Text>
								</View>
							</View> */}
									<EmailInput
										textAlign={"center"}
										setParentState={(d) =>
											this.setState({
												email: d,
											})
										}
									/>
								</View>

								<View
									style={{
										justifyContent: "center",
										// maxWidth: 280,
										marginBottom: 25,
										padding: 10,
									}}>
									{/* <View
								style={
									{
										// justifyContent: "center",
									}
								}>
								<View
									style={{
										flexDirection: "row",
										marginTop: 5,
										// padding: 10,
										alignItems: "center",
										justifyContent: "center",
										// paddingLeft: 16,
										width: "100%",
									}}>
									<Text
										style={{
											color: "#000",
											fontSize: 12,
										}}>
										<Font weight={500} family='Montserrat'>
											Cell number
										</Font>
									</Text>
								</View>
							</View> */}
									<CellNumber
										title={"Cell number"}
										textAlign={"center"}
										setParentState={(d) =>
											this.setState({
												cell: d,
											})
										}
									/>
								</View>

								{/* <View>
								<div
									style={{
										flex: 1,
										alignItems: "center",
										justifyContent: "center",
										marginBottom: 25,
										padding: 10,
									}}>
									<View
										style={{
											flexDirection: "row",
											marginBottom: 5,
											alignItems: "center",
											justifyContent: "center",
											width: "100%",
										}}>
										<Text
											style={{
												color: "#000",
												fontSize: 12,
											}}>
											<Font weight={500} family='Montserrat'>
												Date of birth
											</Font>
										</Text>
									</View>

									<DateOfBirth
										setParentState={(d) =>
											this.setState({
												dob: d,
											})
										}
									/>
								</div>
							</View> */}

								<View
									style={{
										flex: 1,
									}}>
									<div
										style={{
											flex: 1,
											// alignItems: "center",
											// justifyContent: "center",
											marginBottom: 50,
											padding: 10,
										}}>
										<OptIn
											screenWidth={window.innerWidth}
											screenHeight={window.innerHeight}
											setParentState={(d) =>
												this.setState({
													opt_in: d,
												})
											}
										/>
										<View
											style={{
												height: 10,
											}}
										/>
										<Policy
											screenWidth={window.innerWidth}
											screenHeight={window.innerHeight}
											setParentState={(d) =>
												this.setState({
													policy: d,
												})
											}
										/>
									</div>
								</View>

								{this.state.first_name !== "" &&
								this.state.last_name !== "" &&
								(this.state.email !== "" ||
									this.state.cell !== "") &&
								this.state.policy ? (
									<View
										ref={(ref) => (this.myRef = ref)}
										onLayout={() =>
											this.myRef.scrollIntoView({
												behavior: "smooth",
												block: "start",
											})
										}>
										<TouchableOpacity
											style={{
												width: "100%",
												marginTop: 15,
												marginBottom: 40,
											}}
											onPress={async () => {
												// this.props.close();

												try {
													this.setState({
														loading: true,
													});
													const response =
														await fetch(
															"https://workflow.tetrice.co.za/webhook/84569d82-f7d3-4c92-863e-e08e861ae9d9",
															{
																method: "POST",
																headers: {
																	Accept: "application/json",
																	"Content-Type":
																		"application/json",
																	Origin: "",
																	dataType:
																		"json",
																},
																redirect:
																	"follow",
																body: JSON.stringify(
																	this.state,
																),
															},
														);
													const result =
														await response.json();
													console.log(
														"Success:",
														result,
													);
													this.setState({
														successful: true,
													});
												} catch (error) {
													console.error(
														"Error:",
														error,
													);
												}
											}}>
											<View
												style={{
													backgroundColor: "#000",
													width: "100%",
													height: 45,
													borderRadius: 25,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														alignItems: "center",
														justifyContent:
															"center",
														color: "#FFF",
														fontSize: 15,
													}}>
													<Font
														weight={400}
														family='Montserrat'>
														Send
													</Font>
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								) : (
									<View />
								)}

								<View
									style={{
										width: "100%",
										height: 50,
										// marginBottom: 12,
										justifyContent: "center",
										alignItems: "center",
										flexDirection: "row",
										// borderTopWidth: 0.5,
										opacity: 0.8,
										position: "absolute",
										zIndex: 999,
										bottom: 0,
									}}>
									<Text
										style={{
											fontSize: 11,
											color: "#141D31",
										}}>
										© {moment().format("YYYY")} WebForm
										Powered by{" "}
									</Text>

									<Text
										style={{
											fontSize: 12,
											paddingTop: 1,
											color: "#141D31",
										}}>
										<a
											style={{
												textDecoration: "none",
												marginTop: 20,
												color: "#141D31",
											}}
											target='_blank'
											rel='noreferrer'
											href='https://www.tetrice.co.za'>
											<Font
												weight={800}
												family='Poppins'
												color={"#141D31"}>
												tetrice
											</Font>
										</a>
									</Text>
								</View>
							</View>
						</ScrollView>
					</Fade>

					<Modal
						animationType='fade'
						transparent={true}
						visible={this.state.successful}>
						<Fade>
							<View
								style={{
									width: window.innerWidth,
									height: window.innerHeight,
									backgroundColor: "rgba(0, 0, 0, 0.6)",
									// justifyContent: "center",
									// alignItems: "center",
								}}>
								<TouchableOpacity
									activeOpacity={1}
									style={{
										width: window.innerWidth,
										height: window.innerHeight,
										backgroundColor: "rgba(0, 0, 0, 0.6)",
										zIndex: 10,
										position: "absolute",
										top: 0,
									}}
									onPress={() => {
										this.setState({
											accept: true,
											showSignaturePad: false,
										});
									}}
								/>
								<View
									style={{
										width: window.innerWidth,
										height: window.innerHeight,
										bottom: 0,
										zIndex: 999,
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 4,
										},
										shadowOpacity: 0.23,
										shadowRadius: 4.65,
										elevation: 8,
										backgroundColor: "#000",
										borderRadius: 20,
										padding: 10,
										justifyContent: "center",
										alignItems: "center",
									}}>
									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
										}}>
										{/* <Text
										style={{
											color: "#FFF",
											fontSize: 80,
										}}>
										<Font family='Poppins' weight={800}>
											tetrice
										</Font>
									</Text> */}
										<Text
											style={{
												fontSize: 25,
												color: "#FFF",
												marginTop: 50,
											}}>
											<Font family='Poppins' weight={800}>
												Thank you for your enquiry!
											</Font>
										</Text>
										<Text
											style={{
												fontSize: 18,
												color: "#FFF",
											}}>
											<Font family='Poppins' weight={300}>
												Please check your email for our
												correspondence.
											</Font>
										</Text>

										<TouchableOpacity
											style={{
												width: "100%",
												marginTop: 100,
												marginBottom: 40,
											}}
											onPress={async () => {
												this.props.close();
											}}>
											<View
												style={{
													backgroundColor:
														APP_COLOURS.BLUE,
													width: "100%",
													height: 45,
													borderRadius: 25,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														alignItems: "center",
														justifyContent:
															"center",
														color: "#FFF",
														fontSize: 15,
													}}>
													<Font
														weight={400}
														family='Montserrat'>
														Done
													</Font>
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								</View>
							</View>
						</Fade>
					</Modal>
				</View>
			</Modal>
		);
	}
}
