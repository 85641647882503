import React, { Component } from "react";
import { View } from "react-native-web";
import { ReactSVG } from "react-svg";

import background from "../images/background.svg";
import background2 from "../images/background_m.svg";
import dotgroup from "../images/dotgroup.svg";
import dot from "../images/dot.svg";

class BgLayer2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bgImage: null,
			windowWidth: window.innerWidth,
		};

		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
		this.handleResize(); // Initial call to set the image based on initial screen size
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize() {
		// if (this.state.windowWidth <= 700 && window.innerWidth > 700) {
		// 	this.setState({
		// 		windowWidth: window.innerWidth,
		// 	});
		// }
		// if (this.state.windowWidth >= 700 && window.innerWidth < 700) {
		// 	this.setState({
		// 		windowWidth: window.innerWidth,
		// 	});
		// }

		this.setState({
			windowWidth: window.innerWidth,
		});
	}

	render() {
		let { bgImage, windowWidth } = this.state;

		let breakPoint = 1300;

		if (windowWidth <= 1000) {
			return (
				<View
					style={{
						flex: 1,
						// height: window.innerHeight,
						// width: windowWidth,
						// backgroundColor: "red",
					}}>
					{/* <View
						style={{
							width: "100%",
							height: window.innerHeight * 0.8,
							zIndex: 1,
						}}></View> */}
				</View>
			);
		} else
			return (
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						width: "100%",
						// height: window.innerHeight * 0.8,
						overflow: "hidden",
					}}>
					<View
						style={{
							position: "absolute",
							right: 0,
							top: 0,
							width: "100%",
							height: window.innerHeight,
							zIndex: 1,
						}}></View>

					<View
						style={{
							width: "60%",
							position: "relative",
							zIndex: 10,
						}}>
						{windowWidth >= breakPoint ? (
							<ReactSVG src={background} />
						) : (
							<ReactSVG src={background2} />
						)}
						<View
							style={{
								position: "absolute",
								left: 0,
								bottom: 0,
							}}>
							<ReactSVG src={dot} />
						</View>
						<View
							style={{
								position: "absolute",
								left: "80%",
								top: -100,
								opacity: 0.3,
							}}>
							<ReactSVG src={dotgroup} />
						</View>
					</View>

					<View style={{ flex: 1, zIndex: 11 }}></View>
				</View>
			);
	}
}

export default BgLayer2;
